<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgMerchantUserAdd ref="dlgCommon" :visible="visible" @close="dlgClose"></DlgMerchantUserAdd>
        <div class="pa-3">
            <v-toolbar>
            <v-btn v-if="this.merchant_name!='All'" icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ merchant_name}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
            </v-toolbar>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark class="primary mr-2" @click="refreshDataTable"><v-icon>mdi-refresh</v-icon></v-btn>         
        <v-btn icon dark class="mb-2 success" @click="addRecord">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        </v-card-title>
        <v-card-text>

        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-text>

        <v-data-table
        v-model="selected"  
        ref="vuetable"
        item-key="created_date"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"    
        @click:row="openDetail"
        ></v-data-table>
        </v-card>
        </div>

    </v-card>

</template>
<script>
//import axios from 'axios';
import DlgMessage from '@/components/DlgMessage'
import ApMerchantService from "@/services/ApMerchantService"
import DlgMerchantUserAdd from "@/components/merchants/DlgMerchantUserAdd";
export default {
    components:{DlgMessage,DlgMerchantUserAdd},
    data() {
        return{
            //breadcrumb
            items: [
              {text:'Merchants',disabled: false,href:'/merchants',},
              {text:'User',disabled: false,href:'/merchants/users',},
              {text:'List',disabled: false,href:'#',},
              ],
            selected: [],
            tblHeaders:[
                { text:"Merchant", value:"merchant_name" },
                { text:"User Id", value:"user_login_id" },
                { text:"User Password", value:"user_password" },
                { text:"Full Name", value:"full_name" },
                { text:"Status", value:"status" },
            ],
            tblBody:[],
            tblSearch:'',
            loadingFlag : false,
            errorFlag : false,
            visible:false,
            form:{
                merchant_id:0,
                login_id:'',
            },

        }
    },
    async created() {
      if(this.$route.params.merchant_id){
        this.form.merchant_id = this.$route.params.merchant_id;
        this.merchant_name = atob(this.$route.params.enc_merchant_name);
    }else{
        this.form.merchant_id = 0;
        this.merchant_name = 'All'
    }	
      this.refreshDataTable();
    },
    methods:{
            async refreshDataTable(){
                this.loadingFlag = true
                try{
                    ApMerchantService.list_merchant_user(this.form).then((res) => {
                        //console.log(res);
                        this.tblBody = res.data.data;
                        this.loadingFlag = false;
                        this.errorFlag = false;
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error','Merchant User',e.response.data.error_message);
                        this.loadingFlag = false
                        this.errorFlag = true;
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error','Merchant User','Unknown Error');
                  
                }           
            },
			openDetail(obj){
				console.log(obj.merchant_user_id)
				this.$router.push({ name: 'muser-details', 
					params: { 
						merchant_id: this.form.merchant_id,
						enc_merchant_name: btoa(this.merchant_name),
						user_id:obj.merchant_user_id,
                        enc_full_name:btoa(obj.full_name),
					} 
				})

			},

            addRecord(){
                this.visible = true;
            },
            dlgClose(){
                this.visible = false;
                if(!this.errorFlag)
                    this.refreshDataTable();
            },

    }

}
</script>